const crossFadeStep = 250 / sampleRate;

registerProcessor(
  'TriggeredSamplerProcessor',
  class TriggeredSamplerProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'detune',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    position = Infinity;
    buffer: Float32Array | null;
    crossFadePosition = Infinity;
    crossFadeLevel = 0;

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.buffer = options.processorOptions.buffer;

      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'buffer':
            this.buffer = value;
            this.crossFadeLevel = 1;
            this.crossFadePosition = this.position;
            this.position = Infinity;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      { clock: [clock], detune: [detune] }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastClock <= 0 && clock > 0) {
        if (this.buffer && this.position < this.buffer.length - 1) {
          this.crossFadePosition = this.position;
          this.crossFadeLevel = 1;
        } else {
          this.crossFadeLevel = 0;
        }

        this.position = 0;
        this.port.postMessage(['trigger']);
      }
      this.lastClock = clock;

      if (
        this.buffer === null ||
        (this.position > this.buffer.length - 1 &&
          (this.crossFadeLevel > 0 ||
            this.crossFadePosition > this.buffer.length - 1))
      )
        return true;

      const rate = Math.pow(2, detune / 1200);
      for (let i = 0; i < 128; i++) {
        this.position += rate;

        output[i] = this.readInterpolated(this.position, this.buffer);

        if (this.crossFadeLevel > 0) {
          this.crossFadePosition += rate;
          this.crossFadeLevel -= crossFadeStep;
          output[i] =
            output[i] * (1 - this.crossFadeLevel) +
            this.readInterpolated(this.crossFadePosition, this.buffer) *
              this.crossFadeLevel;
        }
      }

      return true;
    }

    private readInterpolated(position: number, buffer: Float32Array): number {
      const readIndexA = Math.floor(position);
      const readIndexB = Math.ceil(position);
      const linearInterpolationFactorA = position - readIndexA;
      const linearInterpolationFactorB = 1 - linearInterpolationFactorA;

      const valueA = buffer[readIndexA];
      if (valueA === undefined) return 0;

      let valueB = buffer[readIndexB];
      if (valueB === undefined) valueB = 0;

      return (
        valueA * linearInterpolationFactorA +
        valueB * linearInterpolationFactorB
      );
    }
  },
);
